import React from "react";
import PropTypes from "prop-types";
import "./section-list-item.scss";

const SectionListItem = ({icon,content,cssClass}) => (
    
    <li className={`section-list-item--wrapper ${cssClass}`}>
        <div className="section-list-item--icon">{icon}</div>
        {content}
    </li>
);

SectionListItem.propTypes = {
    icon: PropTypes.object,
    content: PropTypes.string,
    cssClass: PropTypes.string    
  }
  
SectionListItem.defaultProps = {
    icon: null,
    cssClass: null
}

export default SectionListItem