import React from "react";
import "./application-images.scss";

const ApplicationImages = ({mobile,desktop}) => (
    <div className="application-images--wrapper">
        <div className="application-images--mobile">
            {mobile}
        </div>
        <div className="application-images--desktop">
            {desktop}
        </div>
    </div>
);

export default ApplicationImages