import React from "react";
import PropTypes from "prop-types";
import "./section-list.scss";
import SectionListItem from "../section-list-item/section-list-item";

function renderItems(items,cssClass){
    return items.map( (item,index) => {
        return <SectionListItem key={index} icon={item.icon} content={item.content} cssClass={cssClass} />;
    })
}

const SectionList = ({items,cssClass}) => (
    <ul className="section-list--wrapper">
        {renderItems(items,cssClass)}
    </ul>
);

SectionList.propTypes = {
    items: PropTypes.array,
  }
  
SectionList.defaultProps = {
    items: null,
}

export default SectionList