import React from "react";
import Section from "./../section/section";
import "./section-images.scss";

export default class SectionImages extends React.Component {
    render() {
        const type = this.props.type || 'secondary';

        return <Section
            navClass={this.props.navClass}
            extraClass="section-images"
            type={type} 
            text={this.props.column}
            content={this.props.images}></Section> 
    }
}